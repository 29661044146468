import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'overrides/axios';
import type {
    FirebaseHome,
    HomeResponseKeys,
    HomeResponse,
    HomeItemPayload
} from 'types';
import type { FetchResponseError } from 'types/fetch';
import {
    getDatabaseValue,
    createDatabaseValue
} from 'overrides/firebase.database';
import URIS from './uris.json';

export const pagesQueryKeys = {
    HOME: 'pages.home'
};

const getPagesHome = async (): Promise<FirebaseHome> => {
    const [response, firebaseData] = await Promise.all([
        axios.get<HomeResponse>(URIS.pages.home),
        getDatabaseValue<FirebaseHome>('/home')
    ]);

    // Merge axios response with Firebase home data if a section is missing its data,
    // then filter out any sections with empty or missing data.
    // @ts-ignore
    return firebaseData
        .map(item => {
            if (!item.data && response.data?.[item.id as HomeResponseKeys]) {
                return {
                    ...item,
                    data: response.data[item.id as HomeResponseKeys]
                };
            }

            return item;
        })
        .filter(item => item.data && item.data.length > 0);
};

export const homeQuery = {
    queryKey: [pagesQueryKeys.HOME],
    queryFn: getPagesHome,
    staleTime: Infinity,
    cacheTime: 1000 * 60 * 60 * 24
};

export const usePagesHomeQuery = (
    options?: UseQueryOptions<FirebaseHome, FetchResponseError, FirebaseHome>
) => {
    return useQuery<FirebaseHome, FetchResponseError, FirebaseHome>({
        ...options,
        ...homeQuery
    });
};

/**
 * Removes a home section from Firebase.
 * @param section - The home section key (HomeResponseKeys) to remove.
 */
export const useRemoveHomeSectionMutation = () => {
    return useMutation({
        mutationFn: async (section: HomeResponseKeys) => {
            const homeData = await getDatabaseValue<FirebaseHome>('/home');
            const updatedData = homeData.filter(item => item.id !== section);

            return createDatabaseValue('/home', updatedData);
        }
    });
};

/**
 * Adds a new home section to Firebase.
 * @param section - The home section key (HomeResponseKeys) to add.
 */
export const useAddHomeSectionMutation = () => {
    return useMutation({
        mutationFn: async (section: string) => {
            const homeData = await getDatabaseValue<FirebaseHome>('/home');

            if (homeData.find(item => item.id === section)) {
                throw new Error('Section already exists');
            }

            const newSection = { id: section, data: [] };
            const updatedData = [...homeData, newSection];

            return createDatabaseValue('/home', updatedData);
        }
    });
};

/**
 * Adds a new item to a specific home section in Firebase.
 */
export const useAddHomeItem = () => {
    return useMutation({
        mutationFn: async ({ sectionId, item }: HomeItemPayload) => {
            const homeData = await getDatabaseValue<FirebaseHome>('/home');
            const sectionIndex = homeData.findIndex(
                section => section.id === sectionId
            );

            if (sectionIndex === -1) {
                throw new Error(`Section ${sectionId} not found`);
            }

            const section = homeData[sectionIndex];
            const updatedSection = {
                ...section,
                data: [...section.data, item]
            };
            const updatedHomeData = homeData.map((sec, index) =>
                index === sectionIndex ? updatedSection : sec
            );

            return createDatabaseValue('/home', updatedHomeData);
        }
    });
};
