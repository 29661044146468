import { lazy } from 'react';

export default lazy(
    () => import(/* webpackChunkName: "dashboard" */ './Dashboard')
);

const Music = lazy(
    () => import(/* webpackChunkName: "dashboard-music" */ './music')
);

const Users = lazy(
    () => import(/* webpackChunkName: "dashboard-users" */ './users')
);

const Contents = lazy(
    () => import(/* webpackChunkName: "dashboard-contents" */ './contents')
);

const HomeDash = lazy(
    () => import(/* webpackChunkName: "dashboard-homepage" */ './homedash')
);

const PushNotifications = lazy(
    () =>
        import(
            /* webpackChunkName: "dashboard-push-notifications" */ './push-notifications'
        )
);

export { Music, Users, Contents, PushNotifications, HomeDash };
