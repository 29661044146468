import {
    getDatabase,
    onValue,
    ref,
    set,
    update,
    remove
} from 'firebase/database';
import app from 'overrides/firebase';

// Initialize database
const database = getDatabase(app);

/**
 * Fetches a snapshot value from the given database path.
 */
export const getDatabaseValue = <T>(path: string): Promise<T> => {
    return new Promise<T>((resolve, reject) => {
        onValue(
            ref(database, path),
            snapshot => {
                if (snapshot.exists()) {
                    resolve(snapshot.val() as T);
                } else {
                    reject(null);
                }
            },
            { onlyOnce: true }
        );
    });
};

/**
 * Creates or replaces data at the specified path.
 *
 * @param path - The database path where data will be stored.
 * @param data - The data to store.
 */
export const createDatabaseValue = <T>(
    path: string,
    data: T
): Promise<void> => {
    return set(ref(database, path), data);
};

/**
 * Updates specific fields at the specified path.
 *
 * @param path - The database path to update.
 * @param data - An object containing the fields to update.
 */
export const updateDatabaseValue = <T>(
    path: string,
    data: Partial<T>
): Promise<void> => {
    return update(ref(database, path), data);
};

/**
 * Deletes data at the specified path.
 *
 * @param path - The database path to delete.
 */
export const deleteDatabaseValue = (path: string): Promise<void> => {
    return remove(ref(database, path));
};

export default database;
